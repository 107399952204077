import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import "react-datepicker/dist/react-datepicker.css";
import InvoiceTable from "./invoiceTable";
import { useGetInvoicesMutation } from "../../../../../services/invoice.service";
import dayjs from "dayjs";
import TicketFilter from "../../../../../components/TicketFilter";
import { formatNumber } from "../../../../../utils";
import { useSelector } from "react-redux";

const Invoices = () => {
  const [fetchRecords, {data, isLoading}] = useGetInvoicesMutation();
  const {doRefresh, showFilter, doExport} = useSelector(state => state.ui);
  const [invoices, setInvoices] = useState([]);

  const [filterData, setFilterData] = useState({
    companyName: '',
    status: '',
    generator: '',
    startDate: dayjs().startOf('M').toDate(),
    endDate: dayjs().toDate(),
    type: 'history',
    page: 1,
  })

  useEffect(() => {
    fetchRecords(filterData);
  }, []);

  useEffect(() => {
    if (doRefresh) {
      fetchRecords({
        companyName: '',
        status: '',
        generator: '',
        startDate: dayjs().startOf('M').toDate(),
        endDate: dayjs().toDate(),
        type: 'history',
        page: 1,
      })
    } 
  }, [doRefresh]);

  useEffect(() => {
    if (data) setInvoices(data?.invoices);
  }, [data]);

  const onChange = (value, field) => {
    setFilterData({
      ...filterData,
      [field]: value
    })
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Invoices"
            title="Dashboard"
            subtitle="Invoices"
            modal="#"
            name="Create Invoice"
            link="/create-invoice"
            canRefresh={true}
            canFilter={true}
            canExport={true}
          />

          <div class="row">
            <div class="col-md-3 d-flex">
              <div class="stats-info w-100 mb-0">
                <h6>Total Paid</h6>
                <h4>{formatNumber(data?.totalPaid) || 0}</h4>
              </div>
            </div>
            <div class="col-md-3 d-flex">
              <div class="stats-info w-100 mb-0">
                <h6>Outstanding</h6>
                <h4>{formatNumber(data?.outstanding) || 0}</h4>
              </div>
            </div>
            <div class="col-md-3 d-flex">
              <div class="stats-info w-100 mb-0">
                <h6>Clients</h6>
                <h4>{data?.totalClients || 0}</h4>
              </div>
            </div>
            <div class="col-md-3 d-flex">
              <div class="stats-info w-100 mb-0">
                <h6>Generators</h6>
                <h4>{data?.generators || 0}</h4>
              </div>
            </div>
          </div>

          <hr />
          {showFilter &&
            <TicketFilter onChange={onChange} filterData={filterData} doSearch={() => fetchRecords(filterData)} type="history" />
          }
          <InvoiceTable data={invoices} loading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default Invoices;
