import { createSlice } from "@reduxjs/toolkit";


export const uiSlice = createSlice({
  name: "user",
  initialState: { 
    deleteModal: {
      open: false,
      url: null,
      title: null,
      item: null
    },
    alert: {type: null, show: false, message: ''},
    settings: null,
    showFilter: false,
    doRefresh: false,
    doExport: false
  },
  reducers: {
    toggleDelete: (state, { payload }) => {
      const newState = { ...state };
      newState.deleteModal = payload;
      return newState;
    },
    toggleRefresh: (state) => {
      const newState = { ...state };
      newState.doRefresh = !state.doRefresh;
      return newState;
    },
    toggleFilteer: (state) => {
      const newState = { ...state };
      newState.showFilter = !state.showFilter;
      return newState;
    },
    toggleExport: (state, { payload }) => {
      const newState = { ...state };
      newState.doExport = !state.doExport;
      return newState;
    }
  },
});

export const { toggleDelete, toggleExport, toggleFilteer, toggleRefresh } = uiSlice.actions;
export default uiSlice.reducer;
